import store from '@/store'

import { computed } from '@vue/composition-api'

export default function useCalendarSidebar() {
  // ------------------------------------------------
  // agents
  // ------------------------------------------------
  const agents = computed(() => store.state.calendar.agents);

  // ------------------------------------------------
  // selectedAgents
  // ------------------------------------------------
  const selectedAgents = computed({
    get: () => store.state.calendar.selectedAgents,
    set: val => {
      store.commit('calendar/SET_SELECTED_EVENTS', val)
    },
  })

  const selectedProject = computed({
    get: () => store.state.calendar.selectedProject,
    set: val => {
      store.commit('calendar/setSelectedProject', val)
    },
  })

  const selectedLanguage = computed({
    get: () => store.state.calendar.selectedLanguage,
    set: val => {
      store.commit('calendar/setSelectedLanguage', val)
    },
  })

  // ------------------------------------------------
  // checkAll
  // ------------------------------------------------
  // const checkAll = computed({
  //   /*
  //     GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
  //     SET: If value is `true` => then add all available options in selected filters => Select All
  //          Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
  //   */
  //   get: () => selectedAgents.value.length === agents.value.length,
  //   set: val => {
  //     if (val) {
  //       selectedAgents.value = agents.value.map(i => i.id)
  //     } else if (selectedAgents.value.length === agents.value.length) {
  //       selectedAgents.value = []
  //     }
  //   },
  // })

  return {
    // agents,
    selectedProject,
    selectedLanguage,
    selectedAgents,
    // checkAll,
  }
}
