<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
     
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Language</span>
        </h5>
         <multiselect
          id="language_select"
          v-model="selectedLanguage"
          select-label=""
          deselect-label=""
          label="title"
          :options="languages"
        />
      </div>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Projects</span>
        </h5>
         <multiselect
          id="project_select"
          v-model="selectedProject"
          select-label=""
          deselect-label=""
          label="name"
          :options="projects"
        />
      </div>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Agent List</span>
        </h5>
       
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedAgents"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in agents"
              :key="item.label"
              name="event-filter"
              :value="item.id"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import store from '@/store'
import { mapGetters } from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      selectedLanguage,
      selectedProject,
      selectedAgents,
      // checkAll,
    } = useCalendarSidebar()

    return {
      selectedLanguage,
      selectedProject,
      selectedAgents,
      // checkAll,
    }
  },
  mounted(){
    // console.log(store);
    store.dispatch('calendar/getAgents');
  },
  computed:{

    ...mapGetters({
      agents: "calendar/agents",
      projects: "calendar/projects",
      languages: "calendar/languages",
      
    }),
  }
}
</script>

<style>

</style>
