import axios from '@axios'
import AppointmentService from "@/http/appointment/appointmentService";

export default {
  namespaced: true,
  state: {
    agents: [

    ],
    selectedAgents: [],
    selectedProject: null,
    selectedLanguage: null,
    languages: [
      {
        title: 'English',
        code: 'en'
      },
      {
        title: 'Turkey',
        code: 'tr'
      },


    ],
    projects: []
  },
  getters: {
    agents(state) {
      return state.agents;
    },
    projects(state) {
      return state.projects;
    },
    languages(state) {
      return state.languages;
    },
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedAgents = val
    },
    setSelectedProject(state, val) {
      state.selectedProject = val
    },
    setSelectedLanguage(state, val) {
      state.selectedLanguage = val
    },
    setAgents(state, payload) {
      state.agents = payload;
    },
    setProjects(state, payload) {
      state.projects = payload;
    }
  },
  actions: {
    fetchEvents(ctx, { agents, project, language }) {

      return AppointmentService.getAppointments(
        `view=calendar&agents=${agents.join(',')}&project=${project ? project.id : ''}&language=${language ? language.code : ''}`
      )
      return new Promise((resolve, reject) => {
        axios
          .get('/api/appointments', {
            params: {
              view: 'calendar',
              agents: agents.join(','),
              project: project ? project.id : '',
              language: language ? language.code : '',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAgents({ commit }) {
      AppointmentService.getAgents().then(response => {
        let agents = [];
        response.data.result.ana_agent.forEach(ana_agent => {
          agents.push({
            label: ana_agent.name,
            color: 'primary',
            id: ana_agent.id
          })
        });
        // console.log(agents);
        commit('setAgents', agents)
        commit('setProjects', response.data.result.project);
      })
    },

  },
}
